<template>
  <b-card no-body>
    <b-overlay :show="isShow" rounded="lg" opacity="0.6">
      <b-form @submit.prevent="getOrders">
        <b-card-body>
          <b-row>
            <b-col>
              <b-tabs pills nav-class="nav-pill-primary">
                <b-tab @click="selectedTab('all')" title="Tüm Siparişler"/>
                <b-tab @click="selectedTab('new')" title="Yeni"/>
                <b-tab @click="selectedTab('packed')" title="Kargo Bekliyor"/>
                <b-tab @click="selectedTab('shipped')" title="Kargoda"/>
                <b-tab @click="selectedTab('delivered')" title="Teslim Edilmiş"/>
                <b-tab @click="selectedTab('canceled')" title="İptal Edilmiş"/>
                <b-tab @click="selectedTab('others')" title="Diğerleri"/>
              </b-tabs>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="10" sm="12">
              <b-row>
                <b-col md="2">
                  <b-input size="sm" v-model="_filter.id" placeholder="Sipariş Sıra"/>
                  <b-input size="sm" v-model="_filter.order_number" placeholder="Sipariş No"/>
                  <b-input size="sm" v-model="_filter.package_number" placeholder="Paket No"/>
                </b-col>
                <b-col md="2">
                  <b-input size="sm" v-model="_filter.customer_name" placeholder="Müşteri Adı (%)"/>
                </b-col>
                <b-col md="2">
                  <b-input size="sm" v-model="_filter.order_product.product.id" placeholder="Ürün Id"/>
                  <b-input size="sm" v-model="_filter.order_product.product.barcode" placeholder="Ürün Barkod"/>
                  <b-input size="sm" v-model="_filter.order_product.product.name" placeholder="Ürün Adı (%)"/>
                </b-col>

                <b-col cols="2">
                  <b-form-group label-for="brand">
                    <v-select
                        v-model="_filter.company_uuids"
                        :options="_userCompanies"
                        label="name"
                        :reduce="item => item.company_uuid"
                        input-id="company"
                        multiple
                        placeholder="Şirketler"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group>
                    <v-select
                        v-model="_filter.limit"
                        label="Limit"
                        :options="[10,25,50,100,200,500]"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group>
                    <v-select
                        v-model="_filter.micro"
                        label="key"
                        :options="[
                            { key : 'Evet', val : 1 },
                            { key : 'Hayır', val : 0 },
                            { key : 'Tümü', val : null },
                        ]"
                        :reduce="item => item.val"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="1" sm="12">
              <b-button-group size="sm">
                <b-button variant="primary" type="submit">Getir</b-button>
                <b-button variant="primary" @click="toApprove">Onayla</b-button>
                <b-dropdown variant="primary" toggle-class="text-decoration-none">
                  <template v-slot:button-content> Sipariş</template>
                  <b-dropdown-item @click="getMPOrders"> Siparişleri Çek</b-dropdown-item>
                  <!--<b-dropdown-item @click="getMPOrders"> Siparişi Çek</b-dropdown-item>-->
                </b-dropdown>
              </b-button-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="2">
              <b-form-checkbox
                  v-model="getSelectAll"
                  v-if="_orders.length>0"
                  class=" custom-control-success"
                  @change="setSelectAllOrder"
              >
                Tümü Seç
              </b-form-checkbox>
            </b-col>
            <b-col md="2">
              <b-button
                  :disabled="$store.state.order.selectOrderIds.length<1"
                  @click="labelPrint"
                  size="sm"
              >
                <feather-icon
                    icon="FileIcon"
                    v-b-tooltip.hover.v-primary
                    title="Etiket Yazdır"
                />
                Etiket Yazdır
              </b-button>
            </b-col>
          </b-row>
        </b-card-body>
      </b-form>
    </b-overlay>
  </b-card>
</template>

<script>
import {mapGetters} from 'vuex'
import vSelect from 'vue-select'

export default {
  name: 'OrderFilter',
  components: {vSelect},
  computed: {
    ...mapGetters('order', ['_orders', '_filter']),
    ...mapGetters('company', ['_userCompanies']),
    getSelectAll: {
      get() {
        if (this._orders.length <= this.$store.state.order.selectOrderIds.length) return true
        return false
      },
      set() {
      }
    },
    userCompanyOptions() {
      let items = {}
      this._userCompanies.forEach(company => {
        items[company.company_uuid] = company.name
      })
      return items
    },
  },
  data: () => ({
    isShow: false,
  }),
  methods: {
    selectedTab(status) {
      let orderStatus
      this.isShow = true
      this._filter.page = 1
      if (status === 'all') {
        orderStatus = []
      } else if (status === 'new') {
        orderStatus = [2, 3]
      } else if (status === 'packed') {
        orderStatus = [4, 5]
      } else if (status === 'shipped') {
        orderStatus = [6]
      } else if (status === 'delivered') {
        orderStatus = [7, 9]
      } else if (status === 'canceled') {
        orderStatus = [8, 11]
      } else if (status === 'others') {
        orderStatus = [1, 12, 13, 14]
      } else if (status === 'notInvoiced') {
        orderStatus = []
      } else {
        orderStatus = []
      }
      this.$store.state.order.filter.order_status_id = orderStatus
      this.$store.dispatch('order/getOrders')
          .then(res => {
            this.isShow = false
          })
    },
    getOrders() {
      this.isShow = true
      this._filter.page = 1
      this.$store.dispatch('order/getOrders')
          .then(res => {
            this.isShow = false
          })
    },
    async getMPOrders() {
      const {value: company_uuid} = await this.$swal({
        title: 'Firma Seçiniz',
        input: 'radio',
        inputOptions: this.userCompanyOptions,
        inputValidator: (value) => {
          if (!value) {
            return 'Bir Şirket Seçmediniz'
          }
        },
      })

      if (company_uuid) {
        this.$store.dispatch('order/getMPOrders', company_uuid)
            .then(res => {
              this.$swal({
                title: res.message,
                icon: res.status,
              })
            })
      }
    },
    async approveInvoices(content) {
      const {value: smsCode} = await this.$swal({
        title: 'Sms Kodu Giriniz',
        input: 'text',
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            return 'SMS Kodu Girmediniz!'
          }
        },
      })

      if (smsCode) {
        content.smsCode = smsCode
        this.$store.dispatch('order/approveInvoices', content)
            .then(res => {
              if (res.status == 'success') {
                this.$swal({
                  title: res.content ? 'İşlem Başarılı' : 'Bir Hata Oluştu',
                  icon: res.content ? 'success' : 'error',
                })
              } else {
                alert('Hata oldu')
              }
            })
      }
    },
    async toApprove() {
      const {value: company_uuid} = await this.$swal({
        title: 'Firma Seçiniz',
        input: 'radio',
        inputOptions: this.userCompanyOptions,
        inputValidator: (value) => {
          if (!value) {
            return 'Bir Şirket Seçmediniz'
          }
        },
      })

      if (company_uuid) {
        this.$store.dispatch('order/toApproveInvoice', company_uuid)
            .then(res => {
              if (res.status == 'success') {
                this.approveInvoices(res.content)
              } else {
                alert('Hata oldu')
              }
            })
      }
    },
    setSelectAllOrder(event) {
      let selected = []
      if (event) {
        this._orders.forEach(order => {
          selected.push(order.id)
        })
      }
      this.$store.state.order.selectOrderIds = selected
    },
    labelPrint() {
      let orders = []
      if (this.$store.state.order.selectOrderIds.length > 0) {
        this._orders.forEach(order => {
          if (this.$store.state.order.selectOrderIds.includes(order.id)) {
            orders.push(order)
          }
        })
        this.$root.$emit('order-print-label', orders)
      }
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
