<template>
  <div id="order-print-label">
    <div class="book" v-for="(order) in orders" :key="order.id">
      <div class="page">
        <div class="subpage">
          <table>
            <tbody>
            <tr>
              <td colspan="2">
                <table>
                  <tbody>
                  <tr>
                    <td class="brand-logo" colspan="4">
                      <img width="250" src="/logo.png" alt="TamYeri Ticaret">
                      <h4>Mutluluğun Tam Yeri</h4>
                    </td>
                    <td>
                      <img width="100"
                           :src="'https://api3.tamyeri.com.tr/storage/images/qr/'+order.store_id+'.png'"
                           alt="TamYeri Ticaret"
                      >
                    </td>
                  </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            </tbody>
          </table>
          <table class="ustbosluk">
            <tbody>
            <tr>
              <td colspan="2"><h4>Müşteri Bilgileri</h4></td>
            </tr>
            <tr>
              <td>Sipariş No</td>
              <td>{{ order.id }} ({{ order.order_number }})</td>
            </tr>

            <tr>
              <td>Alıcı Adı</td>
              <td>{{ order.customer.shipment_name }}</td>
            </tr>
            <tr>
              <td>Alıcı Adres</td>
              <td>{{ order.customer.shipment_full_address }}</td>
            </tr>
            <tr>
              <td>Kargo Barkodu</td>
              <td>
                <VueBarcode
                    :value="order.tracking.tracking_number"
                    format="CODE128"
                    height="60"
                    width="2"
                    marginTop="1"
                    marginBottom="1"
                    fontOptions="bold"
                    font-size="10"
                />
              </td>
            </tr>
            </tbody>
          </table>

          <table>
            <tbody>
            <tr>
              <td class="brand-logo">
                <img height="50" width="50" :src="'/img/py_logo/'+order.store.marketplace.image"/>
              </td>
              <td class="brand-logo">
                <img height="50" width="150" :src="'/img/cargo_logo/'+order.tracking.cargo_company.image_url"/>
              </td>
            </tr>
            </tbody>
          </table>

          <table class="ustbosluk">
            <tbody>
            <tr>
              <td colspan="2"><h4>Ürün Bilgileri</h4></td>
            </tr>
            <tr v-for="(orderProduct,indexOP) in order.order_products" :key="orderProduct.id">
              <td>{{ indexOP + 1 }}</td>
              <td>{{ orderProduct.merchant_sku }}</td>
              <td v-if="orderProduct.product">{{ orderProduct.product.short_name }}</td>
              <td v-else></td>
              <td>{{ orderProduct.quantity }}</td>
            </tr>
            </tbody>
          </table>
          <div>
            <h3>Sayın Müşterimiz: </h3>
            <p>Bazı ürün paketlerinde üretici firma kaynaklı değişiklikler olabilmektedir.
              Kesinlikle ürün içerikleri ve damak tatları değişmemektedir.
              <br>
              Anlayışınız için teşekkür ederiz.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import VueBarcode from 'vue-barcode'

export default {
  name: 'OrderPrintLabel',
  components: { VueBarcode },
  props: {
    orders: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    this.$htmlToPaper('order-print-label', null, () => {
      this.$root.$emit('order-print-label', [])
    })
  },
}
</script>

<style scoped>
</style>
