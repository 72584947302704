<template>
  <b-card no-body :style="orderStyle">
    <b-card-body class="bv-example-row bv-example-row-flex-cols">
      <b-row>
        <b-col md="2">
          <b-row class="text-center">
            <b-col cols="6">
              <h6 class="mb-0">
                <b-form-checkbox
                    v-model="$store.state.order.selectOrderIds"
                    :value="order.id"
                    class="custom-control-success"
                >
                  <b>{{ order.id }}</b>
                </b-form-checkbox>
              </h6>
            </b-col>
            <b-col cols="6">
              <b-badge>
                {{ new Date(order.last_delivery_date).toLocaleString('tr-TR', {dateStyle: 'short',}) }}
              </b-badge>
            </b-col>
          </b-row>

          <b-row class="text-center">
            <b-col cols="6">{{ order.order_number }}</b-col>
            <b-col cols="6">{{ order.package_number }}</b-col>
          </b-row>
          <b-row>
            <OrderPriceTable :order="order" :order-log="order.order_log"/>
          </b-row>
        </b-col>
        <b-col md="6">
          <OrderProduct
              v-for="order_product in order.order_products"
              :key="order_product.id"
              :order="order"
              :order_product="order_product"
              class="d-flex"
          />
        </b-col>
        <b-col md="2">
          <b-badge :variant="order.order_status2.status_color">{{ order.order_status2.status }}</b-badge>
          <b-badge>{{ order.order_status }}</b-badge>
          <h6>{{ order.customer_name }}</h6>
          <h6 v-if="order.customer && order.customer.shipment_name && order.customer_name != order.customer.shipment_name">
            {{ order.customer.shipment_name }}
          </h6>

          {{ order.customer_mail }} <br>

          <div v-if="order.customer">
            {{ order.customer.shipment_city }}
            {{ order.customer.shipment_district }}
          </div>


          <div>
            <img height="50" width="50" :src="'/img/py_logo/'+order.store.marketplace.image"/>

            <a v-if="order.tracking && order.tracking.tracking_link" :href="tracking_link" target="_blank">
              <img height="50" width="150" :src="'/img/cargo_logo/'+order.tracking.cargo_company.image_url"/>
            </a>
            <a v-else>
              <img
                  v-if="order.tracking && order.tracking.cargo_company"
                  height="50"
                  width="150"
                  :src="'/img/cargo_logo/'+order.tracking.cargo_company.image_url"
              />
            </a>

          </div>
          <b-badge v-if="order.tracking && order.tracking.tracking_number">
            {{ order.tracking.tracking_number }}
          </b-badge>
        </b-col>
        <b-col md="2">
          <OrderManagement :order="order"/>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import OrderProduct from '@/views/order/order/OrderProduct.vue'
import OrderManagement from '@/views/order/order/OrderManagement.vue'
import OrderPriceTable from '@/views/order/order/OrderPriceTable.vue'

export default {
  name: 'OrderList',
  components: {
    OrderProduct,
    OrderManagement,
    OrderPriceTable,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  computed: {
    tracking_link() {
      return this.order.tracking.tracking_link ? this.order.tracking.tracking_link : '#'
    },
    orderStyle() {
      if (this.order.micro) return 'background-color: rgb(255, 255, 146, 15%)'
      return ''
    }
  },
}
</script>

<style scoped>

</style>
