<template>
  <div id="order-print-label2">
    <div id="printArea">
      <div class="book" v-for="(order,index) in orders" :key="order.id">
        <div class="page">
          <div class="subpage">
            <div class="isimlik"><b>{{ order.customer_name }}</b></div>
            <div class="baslik">İndirimYeri ({{ index + 1 }})</div>
            <hr>
            <div class="musteriAdres">
              {{ order.customer.shipment_full_address }}<br>
              {{ order.customer.shipment_phone_number }}
            </div>
            <hr>
            <div class="siparisBilgi">
              <span class="siparisIdBilgisi"><b>{{ order.id }} / {{ order.order_number }}</b></span> <br>
              {{
                new Date(order.created_date).toLocaleString("tr-TR", {
                  dateStyle: "short",
                  timeStyle: "short",
                })
              }} -
              {{
                new Date().toLocaleString("tr-TR", {
                  dateStyle: "short",
                  timeStyle: "short",
                })
              }}
              <!--<span style="font-size: 7pt">{{ userData.short_name }}</span>-->
            </div>
            <hr>


            <div class="urunBilgi">
              <div v-for="(orderProduct,indexOP) in order.order_products" :key="orderProduct.id">
                {{ indexOP + 1 }}
                <span> {{ orderProduct.merchant_sku }}</span>
                <span v-if="orderProduct.product"> {{ orderProduct.product.short_name }}</span>
                <span> {{ orderProduct.quantity }}</span><br>
              </div>
            </div>
            <hr>

            <div class="logolar">
              <div class="logolar1">
                <VueBarcode
                    :value="order.tracking.tracking_number"
                    format="CODE128"
                    height="30"
                    width="1"
                    marginTop="1"
                    marginBottom="1"
                    fontOptions="bold"
                    font-size="10"/>
              </div>

              <div class="logolar2">
                <img height="50" width="50" :src="'/img/py_logo/'+order.store.marketplace.image"/>
                <img height="50" width="150" :src="'/img/cargo_logo/'+order.tracking.cargo_company.image_url"/>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueBarcode from 'vue-barcode'

export default {
  name: 'OrderPrintLabel2',
  components: {VueBarcode},
  props: {
    orders: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    this.$htmlToPaper('order-print-label2', null, () => {
      this.$root.$emit('order-print-label2', [])
    })
  },
}
</script>

