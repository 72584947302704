<template>
  <table class="table table-sm" v-if="orderLog">
    <thead>
    <tr>
      <th class="text-center">Gelir/Gider</th>
      <th class="text-center">Yüzde (%)</th>
      <th class="text-center">Fiyat (₺)</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td>Total :</td>
      <td class="text-right"></td>
      <td class="text-right">{{ orderLog.sale_amount }}</td>
    </tr>
    <tr v-if="orderLog.promotion_amount!=0">
      <td>İndirim :</td>
      <td class="text-right">{{ ((orderLog.promotion_amount * -1 / orderLog.sale_amount) * 100).toFixed(2) }}</td>
      <td class="text-right">{{ orderLog.promotion_amount }}</td>
    </tr>
    <tr v-if="orderLog.promotion_amount!=0">
      <td>Satış :</td>
      <td class="text-right"></td>
      <td class="text-right">{{ salesPrice }}</td>
    </tr>

    <tr>
      <td>Komisyon :</td>
      <td class="text-right">
        {{
          ((orderLog.commission_amount * -1 / (orderLog.sale_amount - orderLog.promotion_amount * -1)) * 100).toFixed(2)
        }}
      </td>
      <td class="text-right">{{ orderLog.commission_amount }}</td>
    </tr>

    <tr>
      <td>Net :</td>
      <td class="text-right"></td>
      <td class="text-right">{{ orderLog.net_amount }}</td>
    </tr>
    <tr :class="orderLog.is_cargo_amount?'':'bg-warning'">
      <td>Kargo :</td>
      <td class="text-right">
        {{
          (((orderLog.cargo_amount * (orderLog.cargo_amount > 0 ? 1 : -1)) / (orderLog.sale_amount - orderLog.promotion_amount)) * 100).toFixed(2)
        }}
      </td>
      <td class="text-right">{{ orderLog.cargo_amount > 0 ? orderLog.cargo_amount * -1 : orderLog.cargo_amount }}</td>
    </tr>
    <tr>
      <td>Hizmet Bed. :</td>
      <td class="text-right"> {{
          ((orderLog.platform_service_fee * 1 / (orderLog.sale_amount - orderLog.promotion_amount * -1)) * 100).toFixed(2)
        }}
      </td>
      <td class="text-right">{{ orderLog.platform_service_fee * -1 }}</td>
    </tr>
    <tr v-if="orderLog.other_amount!=0">
      <td>Diğer :</td>
      <td class="text-right">
        {{ ((orderLog.other_amount * -1 / (orderLog.sale_amount - orderLog.promotion_amount * -1)) * 100).toFixed(2) }}
      </td>
      <td class="text-right">{{ orderLog.other_amount }}</td>
    </tr>

    <tr>
      <td>Alış :</td>
      <td class="text-right"></td>
      <td class="text-right">{{ orderLog.order_product_cost * -1 }}</td>
    </tr>
    <tr v-if="orderLog.is_cargo_amount">
      <td>Kar :</td>
      <td class="text-right">
        {{ (profitPrice2 / salesPrice * 100).toFixed(2) }} /
        {{ (profitPrice2 / orderLog.order_product_cost * 100).toFixed(2) }}
      </td>
      <td class="text-right">
        <b-badge :variant="profitPrice2<0?'danger':''">{{ profitPrice2 }}</b-badge>
      </td>
    </tr>
    <tr v-else>
      <td>Kar :</td>
      <td class="text-right">
        {{ (profitPrice / salesPrice * 100).toFixed(2) }} /
        {{ (profitPrice / orderLog.order_product_cost * 100).toFixed(2) }}
      </td>
      <td class="text-right">
        <b-badge :variant="profitPrice<0?'danger':''">{{ profitPrice }}</b-badge>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'OrderPriceTable',
  props: {
    order: {
      type: Object,
      required: true,
    },
    orderLog: {
      type: Object,
      required: false,
    },
  },
  computed: {
    salesPrice() {
      return (this.orderLog.sale_amount - this.orderLog.promotion_amount * -1).toFixed(2);
    },
    profitPrice() {
      if (this.order.order_status_id === 8) return 0.00;
      return (this.orderLog.net_amount - (this.orderLog.order_product_cost * 1 + this.orderLog.platform_service_fee * 1 + this.orderLog.cargo_amount * 1)).toFixed(2)
    },
    profitPrice2() {
      if (this.order.order_status_id === 8) return 0.00;
      return (this.orderLog.net_amount - (this.orderLog.order_product_cost * 1 + this.orderLog.platform_service_fee * 1)).toFixed(2)
    }
  }
}
</script>

<style scoped>

</style>
