<template>
  <b-row>
    <b-overlay :show="show">
      <b-col cols="12">
        <b-button-group size="sm">
          <b-button @click="labelPrint">
            <feather-icon
                icon="FileIcon"
                v-b-tooltip.hover.v-primary
                title="Etiket Yazdır"
            />
          </b-button>
          <b-dropdown title="Sipariş Fatura Detayları" size="sm">
            <template #button-content>
              <feather-icon icon="FileTextIcon"/>
            </template>

            <b-dropdown-item variant="primary"
                             size="sm"
                             :disabled="order.invoice?true:false || createInvoice"
                             @click="createNewInvoice"
            >
              <feather-icon icon="FileTextIcon"/>
              Fatura Kes
            </b-dropdown-item>

            <!--
            <b-dropdown-item variant="primary"
                             size="sm"
                             :disabled="order.invoice?true:false || createInvoice"
                             @click="createDraftBasicInvoice"
            >
              <feather-icon icon="FileTextIcon"/>
              Fatura Kes
            </b-dropdown-item>
            <b-dropdown-item variant="primary"
                             size="sm"
                             :disabled="order.invoice && order.invoice.status=='Onaylandı'?true:false"
                             @click="createDraftBasicInvoice"
            >
              <feather-icon icon="FileTextIcon"/>
              Fatura Onayla
            </b-dropdown-item>
            -->
          </b-dropdown>

          <b-button @click="setPicking">
            <feather-icon
                icon="TruckIcon"
                v-b-tooltip.hover.v-primary
                title="Kargo Bekliyor Yap"
            />
          </b-button>

        </b-button-group>
      </b-col>
      <b-col cols="12" v-if="order.invoice">
        <b-badge :variant="invoiceStatus"
                 class="mt-1"
                 title="Fatura Göster"
                 @click="showNewInvoice"
        >
          {{ order.invoice.number }}
        </b-badge>
      </b-col>
      <b-col cols="12" class="mt-1">
        Sip.Tar: {{ new Date(order.created_date).toLocaleString('tr-TR') }}<br>
        Tes.Tar: {{ new Date(order.last_delivery_date).toLocaleString('tr-TR') }}<br>
      </b-col>
    </b-overlay>
  </b-row>
</template>

<script>
import axios from '@/libs/axios'

export default {
  name: 'OrderManagement',
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    show: false,
    createInvoice: false,
  }),
  computed: {
    invoiceUrl() {
      return process.env.VUE_APP_IMG_URL + 'storage/' + this.order.invoice.url
    },
    invoiceStatus() {
      if (this.order.invoice) {
        if (this.order.invoice.request_status) return 'danger'
        if (this.order.invoice.cancel_appeal) return 'warning'
        if (this.order.invoice.status == 'Onaylandı') return 'success'
        return 'info'
      }
      return ''
    },
  },
  methods: {
    setPicking() {
      this.show = true
      this.$store.dispatch('order/setPicking', this.order.id)
          .then(res => {
            this.order.order_status_id = res.order_status_id
            this.order.order_status2 = res.order_status2
            this.show = false
          })
    },
    labelPrint() {
      let orders = []
      orders.push(this.order)
      this.$root.$emit('order-print-label', orders)
    },
    showInvoice() {
      this.show = true
      this.$store.dispatch('order/getBasicInvoicePDF', this.order.invoice.uuid)
          .then(res => {
            if (res.status == 'success') {
              this.$swal({
                width: '780px',
                html: '<embed src="data:application/pdf;base64,' + res.content + '" type="application/pdf" width="700" height="700" />',
              })
              this.show = false
            }
          })
    },
    showNewInvoice() {
      this.show = true
      axios.get(`/invoice/${this.order.invoice.uuid}`)
          .then(res => {
            this.$swal({
              width: '780px',
              html: '<embed src="data:application/pdf;base64,' + res.data.Data.Value.Data + '" type="application/pdf" width="700" height="700" />',
            })
          })
          .finally(() => {
            this.show = false
          })
    },
    createNewInvoice() {
      this.show = true
      axios.get(`/invoice/create/${this.order.uuid}`)
          .then(res => {
            res.data.forEach(item => {
              if (item.order_id === this.order.id) {
                this.order.invoice = item;
              }
            })
          })
          .finally(() => {
            this.show = false;
          })
    },
    createDraftBasicInvoice() {
      this.show = true
      this.$store.dispatch('order/createDraftBasicInvoice', this.order.uuid)
          .then(res => {
            if (res.status === 'success') {
              this.order.invoice = res.content
              this.show = false
              this.createInvoice = true
            }
          })
    },
  },
}
</script>

<style scoped>

</style>
