<template>
  <div>
    <OrderFilter/>
    <Pagination v-if="_pagination" :pagination="_pagination" @pageClick="pageClick" :isShow="isShow"/>

    <transition-group name="orderslide" type="animation" mode="out-in">
      <OrderList v-for="order in _orders" :key="order.id" :order="order"/>
    </transition-group>
    <Pagination v-if="_pagination" :pagination="_pagination" @pageClick="pageClick" :isShow="isShow"/>

    <!--<OrderPrintLabel v-if="orders.length>0" :orders="orders" style="display: none"/>-->
    <OrderPrintLabel2 v-if="orders.length>0" :orders="orders" style="display: none"/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import OrderFilter from '@/views/order/order/OrderFilter.vue'
import OrderList from '@/views/order/order/OrderList.vue'
import Pagination from '@/store/common/Pagination.vue'
import OrderPrintLabel from '@/views/order/order/OrderPrintLabel.vue'
import OrderPrintLabel2 from "@/views/order/order/OrderPrintLabel2.vue";

export default {
  name: 'OrderHome',
  data: () => ({
    isShow: false,
    orders: [],
    orderInvoice: null,
  }),
  components: {
    OrderFilter,
    OrderList,
    Pagination,
    OrderPrintLabel,
    OrderPrintLabel2
  },
  computed: {
    ...mapGetters('order', ['_orders', '_pagination', '_filter']),
  },
  methods: {
    pageClick(page) {
      this.isShow = true
      this._filter.page = page
      this.$store.dispatch('order/getOrders')
          .then(res => {
            this.isShow = false
          })
    },
  },
  mounted() {
    this.$root.$on('order-print-label', orders => {
      this.orders = orders
    })

    this.$root.$on('order-print-label2', orders => {
      this.orders = orders
    })
  },
}
</script>

<style scoped>
.orderslide-enter {
  opacity: 0;
}

.orderslide-enter-active {
  animation: slideIn 1s ease-in-out forwards;
  transition: opacity 1.5s;
}

.orderslide-leave-active {
  animation: slideOut 1s ease-in-out forwards;
  transition: 1.5s;
  opacity: 0;
}

.orderslide-move {
  transition: transform 1s;
}

@keyframes slideIn {
  from {
    transform: translateX(-1000px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(1000px);
  }
}
</style>
